<template>
  <el-row
    :gutter="10"
    style="display: flex; justify-content: center; width: 100%"
  >
    <!-- <el-col :xs="8" :sm="6" :md="8" :lg="6" :xl="0"><div class="grid-content bg-purple"></div></el-col> -->
    <el-col :xs="4" :sm="6" :md="24" :lg="24" :xl="24">
      <div class="grid-content bg-purple-light">
        <div class="content">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in bannerList1" :key="item.index">
              <img :src="item.itiImgUrl" alt="首页背景图片" />
            </el-carousel-item>
          </el-carousel>
          <!-- 产品和服务 -->
          <div id="Products" style="height: 20px"></div>
          <div class="Products">
            <div class="title">
              <span></span>
              <!-- <h2 v-show="lang == '0'">产品与服务</h2> -->
                  <h2>产品与服务</h2>
              <!-- <span v-show="lang == '1'">Products and services</span> -->
            </div>
            <div class="tabs">
              <div
                class="item"
                v-for="(item, index) in Products"
                :key="index"
                @click="ProductsClick(index)"
              >
                <img
                  :src="Pindex == index ? item.imgUrlHL : item.imgUrl"
                  alt="首页产品切换背景图"
                />
              </div>
            </div>
            <!-- <div class="tabs_Hardware" v-if="Pindex == 0">
              <img
                class="img1"
                @click="prev()"
                src="../assets/icon/left.png"
                alt=""
              />
              <img
                class="img2"
                @click="next()"
                src="../assets/icon/right.png"
                alt=""
              />
              <div class="list">
                <div
                  class="item"
                  v-for="(item, index) in Hardware"
                  :key="index"
                >
                  <div class="devImg">
                    <img :src="item.devImg" alt="" />
                  </div>
                  <div class="text">
                    <p>{{ item.devName }}</p>
                    <button @click="goDetail(item)">详情参数</button>
                  </div>
                </div>

                <button class="gotoHardwareDetail" @click="gotoHardwareDetail">
                  了解更多 >
                </button>
              </div>
            </div> -->
            <div class="tabs_Hardware" v-if="Pindex == 0">
              <img
                class="img1"
                @click="prev()"
                src="../assets/icon/left.png"
                alt="硬件产品表切换左图"
              />
              <img
                class="img2"
                @click="next()"
                src="../assets/icon/right.png"
                alt="硬件产品表切换右图"
              />
              <div class="list">
                <div
                  class="item"
                  v-for="(item, index) in Hardware"
                  :key="index"
                >
                  <a :href="`#/HardwareDetail/${item.devId}`">
                    <div class="devImg">
                      <img :src="item.devImg" alt="硬件产品展示图" />
                    </div>
                    <div class="text">
                      <p>{{ item.devName }}</p>
                      <button>详情参数</button>
                    </div>
                  </a>
                </div>

                <!-- <button class="gotoHardwareDetail" @click="gotoHardwareDetail">
                  了解更多 >
                </button> -->
                <button class="gotoHardwareDetail">
                  <a href="#/product?id=9">了解更多 ></a>
                </button>
              </div>
            </div>

            <!-- <div class="tabs_software" v-else-if="Pindex == 1">
              <div class="lists">
                <div class="items">
                  <div class="project_name">
                    <img src="../assets/home/water-conservancy.png" alt="" />
                    <span style="color: #1296db">智慧水利</span>
                  </div>
                  <div class="list">
                    <div class="item">
                      <img
                        :src="WisdomShui[0].proIcon"
                        alt=""
                        @click="gotoShuiDetail(1)"
                      />
                      <span>{{ WisdomShui[0].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        :src="WisdomShui[1].proIcon"
                        alt=""
                        @click="gotoShuiDetail(21)"
                      />
                      <span>{{ WisdomShui[1].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        :src="WisdomShui[2].proIcon"
                        alt=""
                        @click="gotoShuiDetail(22)"
                      />
                      <span>{{ WisdomShui[2].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        :src="WisdomShui[3].proIcon"
                        alt=""
                        @click="gotoShuiDetail(23)"
                      />
                      <span>{{ WisdomShui[3].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        :src="WisdomShui[4].proIcon"
                        alt=""
                        @click="gotoShuiDetail(24)"
                      />
                      <span>{{ WisdomShui[4].proName }}</span>
                    </div>
                    <div class="more">
                      <span @click="gotoWater_conservancy">了解更多></span>
                    </div>
                  </div>
                </div>
                <div class="items">
                  <div class="project_name">
                    <img src="../assets/home/Urban-management.png" alt="" />
                    <span style="color: #e2852a">智慧城管</span>
                  </div>
                  <div class="list">
                    <div class="item">
                      <img
                        @click="gotoChengDetail(0)"
                        :src="WisdomCheng[0].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[0].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoChengDetail(1)"
                        :src="WisdomCheng[1].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[1].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoChengDetail(2)"
                        :src="WisdomCheng[2].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[2].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoChengDetail(3)"
                        :src="WisdomCheng[3].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[3].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoChengDetail(4)"
                        :src="WisdomCheng[4].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[4].proName }}</span>
                    </div>
                    <div class="more">
                      <span @click="gotoCity_inspectors(1)">了解更多></span>
                    </div>
                  </div>
                </div>
                <div class="items">
                  <div class="project_name">
                    <img src="../assets/home/Agriculture.png" alt="" />
                    <span style="color: #01b49e">智慧农业</span>
                  </div>
                  <div class="list">
                    <div class="item">
                      <img
                        @click="gotoNongDetail(3)"
                        :src="WisdomNong[0].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomNong[0].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoNongDetail(29)"
                        :src="WisdomNong[1].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomNong[1].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoNongDetail(30)"
                        :src="WisdomNong[2].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomNong[2].proName }}</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoNongDetail(31)"
                        :src="WisdomNong[3].proIcon"
                        alt=""
                      />
                      <span>环境监测系统(自动气象站)</span>
                    </div>
                    <div class="item">
                      <img
                        @click="gotoNongDetail(32)"
                        :src="WisdomNong[4].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomNong[4].proName }}</span>
                    </div>
                    <div class="more">
                      <span @click="gotoAgriculture(2)">了解更多></span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="tabs_software" v-else-if="Pindex == 1">
              <div class="lists">
                <div class="items">
                  <div class="project_name">
                    <img
                      src="../assets/home/water-conservancy.png"
                      alt="智慧水利图标"
                    />
                    <span style="color: #1296db">智慧水利</span>
                  </div>
                  <div class="list">
                    <div class="item">
                      <a href="#/softwareDetail/1/1">
                        <img
                          :src="WisdomShui[0].proIcon"
                          alt="大数据可视化图标"
                        />
                      </a>
                      <span>{{ WisdomShui[0].proName }}</span>
                    </div>
                    <div class="item">
                      <a href="#/softwareDetail/21/1">
                        <img :src="WisdomShui[1].proIcon" alt="GIS一张图图标" />
                      </a>
                      <span>{{ WisdomShui[1].proName }}</span>
                    </div>
                    <div class="item">
                      <a href="#/softwareDetail/22/1">
                        <img
                          :src="WisdomShui[2].proIcon"
                          alt="无人值守远程控制系统图标"
                        />
                      </a>
                      <span>{{ WisdomShui[2].proName }}</span>
                    </div>
                    <div class="item">
                      <a href="#/softwareDetail/23/1">
                        <img
                          :src="WisdomShui[3].proIcon"
                          alt="雨情自动遥测系统图标"
                        />
                      </a>
                      <span>{{ WisdomShui[3].proName }}</span>
                    </div>
                    <div class="item">
                      <a href="#/softwareDetail/24/1">
                        <img
                          :src="WisdomShui[4].proIcon"
                          alt="自动水位站监测系统图标"
                        />
                      </a>
                      <span>{{ WisdomShui[4].proName }}</span>
                    </div>
                    <!-- <div class="more">
                      <span @click="gotoWater_conservancy">了解更多></span>
                    </div> -->
                    <div class="more">
                      <a href="#/product?id=0">了解更多></a>
                    </div>
                  </div>
                </div>
                <div class="items">
                  <div class="project_name">
                    <img
                      src="../assets/home/Urban-management.png"
                      alt="智慧城管图标"
                    />
                    <span style="color: #e2852a">智慧城管</span>
                  </div>
                  <div class="list">
                    <!-- <div class="item">
                      <img
                        @click="gotoChengDetail(0)"
                        :src="WisdomCheng[0].proIcon"
                        alt=""
                      />
                      <span>{{ WisdomCheng[0].proName }}</span>
                    </div> -->
                    <div class="item">
                      <a :href="`#/softwareDetail/5/2`">
                        <img
                          :src="WisdomCheng[0].proIcon"
                          alt="智慧城管大数据可视化图标"
                        />
                      </a>
                      <span>{{ WisdomCheng[0].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/6/2`">
                        <img
                          :src="WisdomCheng[1].proIcon"
                          alt="智慧城管GIS一张图图标"
                        />
                      </a>
                      <span>{{ WisdomCheng[1].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/7/2`">
                        <img
                          :src="WisdomCheng[2].proIcon"
                          alt="公共服务系统图标"
                        />
                      </a>
                      <span>{{ WisdomCheng[2].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/8/2`">
                        <img
                          :src="WisdomCheng[3].proIcon"
                          alt="城市运行APP图标"
                        />
                      </a>
                      <span>{{ WisdomCheng[3].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/9/2`">
                        <img
                          :src="WisdomCheng[4].proIcon"
                          alt="智慧水务综合管理平台图标"
                        />
                      </a>
                      <span>{{ WisdomCheng[4].proName }}</span>
                    </div>
                    <div class="more">
                      <!-- <span @click="gotoCity_inspectors(1)">了解更多></span> -->
                      <a href="#/product?id=1">了解更多></a>
                    </div>
                  </div>
                </div>
                <div class="items">
                  <div class="project_name">
                    <img
                      src="../assets/home/Agriculture.png"
                      alt="智慧农业图标"
                    />
                    <span style="color: #01b49e">智慧农业</span>
                  </div>
                  <div class="list">
                    <div class="item">
                      <a :href="`#/softwareDetail/3/3`">
                        <img
                          :src="WisdomNong[0].proIcon"
                          alt="智能化灌溉系统图标"
                        />
                      </a>
                      <span>{{ WisdomNong[0].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/29/3`">
                        <img
                          :src="WisdomNong[1].proIcon"
                          alt="水肥一体化系统图标"
                        />
                      </a>
                      <span>{{ WisdomNong[1].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/30/3`">
                        <img
                          :src="WisdomNong[2].proIcon"
                          alt="远程信息化虫情测报系统图标"
                        />
                      </a>
                      <span>{{ WisdomNong[2].proName }}</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/31/3`">
                        <img
                          :src="WisdomNong[3].proIcon"
                          alt="环境监测系统图标"
                        />
                      </a>
                      <span>环境监测系统(自动气象站)</span>
                    </div>
                    <div class="item">
                      <a :href="`#/softwareDetail/32/3`">
                        <img
                          :src="WisdomNong[4].proIcon"
                          alt="土壤墒情监控系统图标"
                        />
                      </a>
                      <span>{{ WisdomNong[4].proName }}</span>
                    </div>
                    <div class="more">
                      <!-- <span @click="gotoAgriculture(2)">了解更多></span> -->
                      <a href="#/product?id=2">了解更多></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs_other" v-else-if="Pindex == 2">
              <div class="item" v-for="(item, index) in otherList" :key="index">
                <div class="left_img">
                  <img :src="item.acImg" alt="其他服务图片" />
                </div>
                <div class="right_text">
                  <img
                    v-if="index == 0"
                    src="../assets/home/software.png"
                    alt="软件定制开发图标"
                  />
                  <img
                    v-if="index == 1"
                    src="../assets/home/hardware.png"
                    alt="硬件定制研发图标"
                  />
                  <div class="tilte">
                    <span>{{ item.acTit }}</span>
                    <span @click="gotoSoftware(index)">了解更多</span>
                    <!-- <a href="">了解更多</a> -->
                  </div>
                  <p v-html="item.acContent"></p>
                  <div class="service_list" v-if="index == 0">
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>APP定制开发</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>小程序制作</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>网站建设</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>微信开发</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>H5场景制作</span>
                    </div>
                  </div>
                  <div class="service_list" v-if="index == 1">
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>硬件电路设计</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>PCB布板</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>物料清单</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>SMT加工</span>
                    </div>
                    <div class="sitem">
                      <img
                        src="../assets/home/customization_icon.png"
                        alt="功能介绍小图标"
                      />
                      <span>嵌入式编程</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 行业解决方案 -->
          <div class="Solutions">
            <div class="title">
              <span></span>
              <h2 style="color: #fff">行业解决方案</h2>
            </div>
            <div class="describe">
              <span
                >针对不同的业务需求和应用场景，为各行各业提供专业、完整的智慧物联网解决方案</span
              >

              <!-- <div class="describe_p" @click="gotoprogramme1">了解更多 ></div> -->
              <h3 class="describe_p"><a href="#/programme">了解更多 ></a></h3>
            </div>
            <div class="content">
              <div class="item_list" title="智慧水利背景图">
                <div class="lists">
                  <div class="title">
                    <img
                      src="../assets/home/water-conservancy.png"
                      alt="智慧水利图标"
                    />
                    <span>智慧水利</span>
                  </div>
                  <div class="list">
                    <div class="item1">
                      通过对水利对象及水利管理活动进行透彻感知、网络互联、信息共享和智能分析，最终形成“更透彻的感知、更全面的互联互通、更科学的决策、更高效智能的管理”的智慧水利管理体系，为水利业务提供智能处理、决策支持和泛在服务。
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-top: 20px;
                        border-top: 1px dashed #ffffff;
                      "
                    >
                      <!-- <div class="item itemM" @click="gotoShuifn">方案概述</div> -->
                      <div class="item itemM">
                        <a href="#/programme?name=%23content&id=0">方案概述</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoShuijs">建设思路</div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=11">建设思路</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoShuijg">总体架构</div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=12">总体架构</a>
                      </div>
                      <!-- <div class="item" @click="gotoShuinr">建设内容</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=13">建设内容</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoShuign">功能模块</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=14">功能模块</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoShuicp">相关产品</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=15">相关产品</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_list" title="智慧城管背景图">
                <div class="lists">
                  <div class="title">
                    <img
                      src="../assets/home/Urban-management.png"
                      alt="智慧城管图标"
                    />
                    <span>智慧城管</span>
                  </div>
                  <div class="list">
                    <div class="item1">
                      以城市公用设施为突破口，逐步推进市政、环卫、执法、园林等公共设施智慧化运营监管，实现在城区防汛排涝、流动摊贩治理、餐饮油烟整治、城市治安、违法建筑等重点行动中的基础保障作用，为应急指挥、领导决策、公众参与提供服务。
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-top: 20px;
                        border-top: 1px dashed #ffffff;
                      "
                    >
                      <!-- <div class="item itemM" @click="gotoChenggs">
                        方案概述
                      </div> -->
                      <div class="item itemM">
                        <a href="#/programme?name=%23content&id=1"> 方案概述</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoChengjs">
                        建设思路
                      </div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=21">建设思路</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoChengjg">
                        总体架构
                      </div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=22"> 总体架构</a>
                      </div>
                      <!-- <div class="item" @click="gotoChengnr">建设内容</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=23">建设内容</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoChenggn">功能模块</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=24">功能模块</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoChengcp">相关产品</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=25">相关产品</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_list" title="智慧农业背景图">
                <div class="lists">
                  <div class="title">
                    <img
                      src="../assets/home/Agriculture.png"
                      alt="智慧农业图标"
                    />
                    <span>智慧农业</span>
                  </div>
                  <div class="list">
                    <div class="item1">
                      借助精准的农业传感监测设备，对土壤、虫情、气象等不同的农业生产环境及对象进行动态监测监管，使之符合农业生产环境标准，提高农业产生对自然环境风险的应对能力，改善农产品品质，促进农业生产精细化、高效化、现代化发展。
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-top: 20px;
                        border-top: 1px dashed #ffffff;
                      "
                    >
                      <!-- <div class="item itemM" @click="gotoNongfn">方案概述</div> -->
                      <div class="item itemM">
                        <a href="#/programme?name=%23content&id=2">方案概述</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoNongjs">建设思路</div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=31">建设思路</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 25px;
                        "
                      ></span>
                      <!-- <div class="item itemM" @click="gotoNongjg">总体架构</div> -->
                      <div class="item itemM">
                        <a href="#/programme?Tid=32">总体架构</a>
                      </div>
                      <!-- <div class="item" @click="gotoNonggn">功能模块</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=33">功能模块</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoNongHardware">建设目标</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=34">建设目标</a>
                      </div>
                      <span
                        style="
                          display: block;
                          border-left: 1px solid #ffffff;
                          height: 20px;
                          margin-top: 5px;
                        "
                      ></span>
                      <!-- <div class="item" @click="gotoNongSoftware">相关产品</div> -->
                      <div class="item">
                        <a href="#/programme?Tid=35">相关产品</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 新闻资讯 -->
          <div class="News_infor">
            <div class="top">
              <div class="title" style="margin-bottom: 30px">
                <span></span>
                <h2>新闻资讯</h2>
              </div>
              <!-- <div class="more_btn">
                <span @click="gotoNews()">更多资讯 ></span>
              </div> -->
              <div class="more_btn">
                <a href="#/news">更多资讯 ></a>
              </div>
            </div>
            <div class="content_infor">
              <div class="left_infor">
                <!-- <el-carousel :interval="5000" arrow="always">
                  <el-carousel-item
                    v-for="item in bannerList"
                    :key="item.index"
                  > -->
                <img
                  width="635px"
                  height="350px"
                  :src="newsList[0].cnImg"
                  alt="首页新闻展示大图"
                />
                <!-- </el-carousel-item>
                </el-carousel> -->
                <div class="text">
                  <p>{{ newsList[0].createTime.slice(0, 10) }}</p>
                  <p style="margin-top: 5px">{{ newsList[0].cnTit }}</p>
                  <p
                    style="margin-top: 10px"
                    v-html="
                      newsList[0].cnContent.split('<br/>')[0].split('<p>')[1]
                    "
                  ></p>
                  <!-- <span style="margin-top: 10px" @click="gotoNewsDetailLeft()"
                    >新闻详情></span
                  > -->
                  <span style="margin-top: 10px">
                    <a
                      :href="`#/NewsDetail/${newsList[0].cnId}`"
                      style="color: #004aa1"
                      >新闻详情 ></a
                    ></span
                  >
                </div>
              </div>
              <div class="right_infor">
                <div
                  class="infor_item"
                  v-for="(item, index) in newsList1"
                  :key="index"
                >
                  <p>{{ item.createTime.slice(0, 10) }}</p>
                  <p style="margin-top: 5px">{{ item.cnTit }}</p>
                  <p
                    style="margin-top: 5px"
                    v-html="item.cnContent.split('<br/>')[0].split('<p>')[1]"
                  ></p>
                  <!-- <span
                    style="margin-left: 360px; margin-top: 15px"
                    @click="gotoNewsDetailRight(item)"
                    >新闻详情></span
                  > -->
                  <span style="margin-left: 360px; margin-top: 15px"
                    ><a
                      :href="`#/NewsDetail/${item.cnId}`"
                      style="color: #004aa1"
                      >新闻详情 ></a
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 关于我们 -->
          <div class="About_us">
            <div class="bg" title="首页关于我们背景大图"></div>
            <div class="text">
              <div class="title">
                <span></span>
                <h2 style="margin-top: -2px">关于我们</h2>
              </div>
              <div class="title_div">
                <p style="font-weight: 400">
                  东营市旭瑞智能科技股份有限公司是国内领先的智慧物联网解决方案提供商，专注于智慧水利、智慧城管、智慧农业等多个垂直行业软硬件产品的研发、生产与运营，通过对云计算、大数据、人工智能、物联网等技术的深度整合，打造“平台+终端+应用+服务”的一体化模式，搜集、处理、分析联网设备产生的数据并据此采取行动，旨在帮助政府部门和企事业单位优化业务过程，实现业务升级和转型，让连接真正发挥作用，让应用创造更大价值。
                </p>
                <p style="font-weight: 400">
                  旭瑞智能成立于2011年，注册资金510万元，于2016年在齐鲁股权交易中心成长板挂牌（股权代码：301436）。历经多年行业深耕和积累，旭瑞智能现拥有几十项知识产权并取得“双软企业”认定，为客户提供了诸多优良的解决方案和技术应用服务。
                </p>
                <p style="font-weight: 400">
                  未来，旭瑞智能将继续加大技术开发与创新力度，扩大产品种类和应用领域，努力将公司打造成科技型、创新型的智慧物联网领军企业。
                </p>
              </div>

              <!-- <div class="About_us_btn">
                <span @click="gotoAbout()">了解我们></span>
              </div> -->
              <div class="About_us_btn">
                <a href="#/about">了解我们></a>
              </div>
            </div>
          </div>
          <!-- 介绍标签 -->
          <div class="introduce">
            <div class="item">
              <img
                src="../assets/home/High_cost_performance.jpg"
                alt="介绍图标"
              />
              <p>高性价比</p>
              <p>为您提供超高性价比的产品</p>
            </div>
            <div class="item">
              <img src="../assets/home/R&Dstrength.jpg" alt="介绍图标" />
              <p>研发实力</p>
              <p>厚积薄发，10+年研发经验积累</p>
            </div>
            <div class="item">
              <img src="../assets/home/Quality_assurance.jpg" alt="介绍图标" />
              <p>品质保障</p>
              <p>研发生产遵循ISO9000规范</p>
            </div>
            <div class="item">
              <img
                src="../assets/home/Personalized_customization.jpg"
                alt="介绍图标"
              />
              <p>个性定制</p>
              <p>为您提供个性化功能定制服务</p>
            </div>
            <div class="item">
              <img src="../assets/home/7X24-hour_service.jpg" alt="介绍图标" />
              <p>7*24小时服务</p>
              <p>7*24小时真诚为您服务</p>
            </div>
          </div>
          <!-- 咨询联系 -->
          <div class="Inquiry">
            <p>一键开启智慧转型升级之旅</p>
            <p>如果您想了解我们的产品或服务，请与我们联系。</p>
            <div class="btn">
              <!-- <button @click="gotoContact">业务咨询</button> -->
              <button><a href="#/contact?name=%23Message">业务咨询</a></button>

              <!-- <button @click="gotoMerchants">商务合作</button> -->
              <button>
                <a href="#/merchants?name=%23opportunity">商务合作</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title:
      "智慧水利水务信息化_智慧城市大数据_智慧农业管理_解决方案提供商-东营市旭瑞智能科技有限公司", // 设置title
    meta: [
      {
        name: "keywords", // 设置关键字
        content:
          "智慧水利，PLC控制柜，闸泵远程控制，智慧城市，智慧城管，智慧水务，城市防汛监测，智慧闸泵，智慧环卫，智慧公厕，智慧园林，智慧执法，智慧渣土，市容秩序提升，智慧工地，智慧道桥，智慧井盖，智慧管网，智慧照明，智慧农业，智能灌溉，水肥一体化，物联网",
      },
      {
        name: "description", // 设置页面描述
        content:
          "旭瑞智能专注于智慧水利、智慧城管、智慧农业及智能化工程等智慧物联网细分领域软硬件产品研发、生产、销售、运营，提供智慧行业整体解决方案。",
      },
    ],
  },
  data() {
    return {
      HardwareList: [],
      WisdomShui: [],
      WisdomCheng: [],
      WisdomNong: [],
      bannerList1: [
        { itiImgUrl: require("../assets/about/bannera.jpg") },
        { itiImgUrl: require("../assets/about/bannerb.jpg") },
      ],
      NewsBanner: [{ img: require("../assets/icon/kaihui.png") }],
      lang: "",
      Products: [
        {
          imgUrl: require("../assets/home/Hardware.jpg"),
          imgUrlHL: require("../assets/home/Hardware_HL.jpg"),
        },
        {
          imgUrl: require("../assets/home/software.jpg"),
          imgUrlHL: require("../assets/home/software_HL.jpg"),
        },
        {
          imgUrl: require("../assets/home/other.jpg"),
          imgUrlHL: require("../assets/home/other_HL.jpg"),
        },
      ],
      otherList: [],
      Pindex: 0,
      Hardware: [],
      advantageList: [],
      customerList: [],
      newsList: [],
      background: [],
      AboutUsInfor: [],
      newsList1: [],
      pageNum: 1,
      pageSize: 4,
      total: 0,
      page: 0, //点击翻页数
    };
  },
  created() {
    this.rotation();
    this.getHardwareData();
    this.otherService();
    this.getNews();
    this.getArticle();
    this.getWisdomShui();
    this.getWisdomCheng();
    this.getWisdomNong();
    this.getHardware();
    if (this.$route.query.id == 2) {
      this.Pindex = 2;
    }
  },
  mounted() {
    this.lang = localStorage.getItem("newv");
    this.getSoftwareDetail();
  },
  updated() {
    if (this.$route.query.name) {
      this.$el.querySelector(this.$route.query.name).scrollIntoView();
    }
  },
  methods: {
    ...mapMutations(["setNum"]),

    // 硬件产品ALL
    getHardware() {
      let data = {};
      this.$API.getHardware(data).then((res) => {
        console.log("res :硬件产品列表>> ", res);
        this.HardwareList = res.data;
      });
    },
    // 水利单个详情
    gotoShuiDetail(val) {
      let id = val;
      let type = 1;
      this.$router.push({
        path: `/softwareDetail/${id}/${type}`,
      });
    },
    // 城管单个详情
    gotoChengDetail(val) {
      let id = val + 5;
      let type = 2;
      this.$router.push({
        path: `/softwareDetail/${id}/${type}`,
      });
    },
    // 水利单个详情
    gotoNongDetail(val) {
      let id = val;
      let type = 3;
      this.$router.push({
        path: `/softwareDetail/${id}/${type}`,
      });
    },
    // 城管详情
    getSoftwareDetail() {
      let data = {
        proType: 2, //软件类别
        // proId: 1, // 具体参数
      };
      this.$API.getSoftwareDetail(data).then((res) => {
        console.log("res :软件城管详情>> ", res);
      });
    },

    // 智慧水利date
    getWisdomShui() {
      let data = {
        proType: 1,
      };
      this.$API.getSoftware(data).then((res) => {
        console.log("res :软件水利>> ", res);
        this.WisdomShui = res.data.slice(0, 5);
        console.log(this.WisdomShui);
      });
    },
    getWisdomCheng() {
      let data = {
        proType: 2,
      };
      this.$API.getSoftware(data).then((res) => {
        console.log("res :软件城市>> ", res);
        this.WisdomCheng = res.data.slice(0, 5);
      });
    },
    getWisdomNong() {
      let data = {
        proType: 3,
      };
      this.$API.getSoftware(data).then((res) => {
        console.log("res :软件农业>> ", res);
        this.WisdomNong = res.data.slice(0, 5);
      });
    },

    gotoShuifn() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 0,
        },
      });
      this.setNum(0);
    },
    gotoShuijs() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 11,
        },
      });
      this.setNum(0);
    },
    gotoShuijg() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 12,
        },
      });
      this.setNum(0);
    },
    gotoShuinr() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 13,
        },
      });
      this.setNum(0);
    },
    gotoShuign() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 14,
        },
      });
      this.setNum(0);
    },
    gotoShuicp() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 15,
        },
      });
      this.setNum(0);
    },
    gotoChenggs() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 1,
        },
      });
      this.setNum(1);
    },
    gotoChengjs() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 21,
        },
      });
      this.setNum(1);
    },
    gotoChengjg() {
      this.$router.push({
        path: "/programme",
        query: {
          Tid: 22,
        },
      });
      this.setNum(1);
    },
    gotoChengnr() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#undertaking2",
          Tid: 23,
        },
      });
      this.setNum(1);
    },
    gotoChenggn() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#Enterprise_Architecture2",
          Tid: 24,
        },
      });
      this.setNum(1);
    },
    gotoChengcp() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#Related_Products2",
          Tid: 25,
        },
      });
      this.setNum(1);
    },
    gotoNongfn() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 2,
        },
      });
      this.setNum(2);
    },
    gotoNongjs() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#construction3",
          Tid: 31,
        },
      });
      this.setNum(2);
    },
    gotoNongjg() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#Solution_Architecture3",
          Tid: 32,
        },
      });
      this.setNum(2);
    },
    gotoNonggn() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#architecture3",
          Tid: 33,
        },
      });
      this.setNum(2);
      // this.$route.go(0)
    },
    gotoNongHardware() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#Hardware",
          Tid: 34,
        },
      });
      this.setNum(2);
    },
    gotoNongSoftware() {
      this.$router.push({
        path: "/programme",
        query: {
          // name: "#Related_Products3",
          Tid: 35,
        },
      });
      this.setNum(2);
    },
    gotoNongcp() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#Related_Products3",
          id: 2,
        },
      });
      this.setNum(2);
    },
    prev() {
      if (this.pageNum <= "1") {
        this.pageNum = 2;
      }
      let data = {
        pageNum: --this.pageNum,
        pageSize: 4,
      };
      this.$API.getFenYeHardware(data).then((res) => {
        console.log("res :硬件>> ", res);
        this.Hardware = res.data.list;
      });
    },
    next() {
      if (this.pageNum <= this.page) {
        this.pageNum = this.page;
      }

      let data = {
        pageNum: ++this.pageNum,
        pageSize: 4,
      };
      this.$API.getFenYeHardware(data).then((res) => {
        this.Hardware = res.data.list;
      });
    },

    deteil(id) {
      this.$router.push({ path: "/productDetails", query: { id } });
    },
    // 顶部轮播
    rotation() {
      let data = {
        itiItId: 2, // 首页轮播图
      };
      this.$API.getBanner(data).then((res) => {
        console.log("首页---", res);
        this.bannerList = res.data;
        res.data.forEach((v) => {
          this.background.push(v.itiImgUrl);
        });
      });
    },
    // 定制服务
    otherService() {
      let data = {
        infoTypeId: 2,
      };
      this.$API.getArticleList(data).then((res) => {
        this.otherList = res.data.slice(0, 2);
        console.log("this.otherList :>> ", this.otherList);
      });
    },
    ProductsClick(index) {
      this.Pindex = index;
      console.log(this.Pindex, "选择的ID");
    },
    // 硬件产品
    getHardwareData() {
      let data = {
        pageNum: 1,
        pageSize: 4,
      };
      this.$API.getFenYeHardware(data).then((res) => {
        console.log("res :硬件>> ", res);
        this.Hardware = res.data.list;
        this.total = res.data.total;
        // this.page = parseInt(this.total / this.pageNum) + 1;
      });
    },
    // 硬件详情
    goDetail(val) {
      console.log(val);
      let id = val.devId;
      this.$router.push({ path: `/HardwareDetail/${id}` });
    },
    // 软件产品
    getSoftwareData() {
      let data = {
        pageNum: 1,
        pageSize: 4,
      };
      this.$API.getFenYeSoftware(data).then((res) => {
        console.log("res :软件>> ", res);
      });
    },
    // 其他服务
    getOtherData() {},
    // 核心优势
    advantage() {
      this.$http.get("/jeecg-boot/xrcx/coreAdv/list").then((res) => {
        this.advantageList = res.result.records;
      });
    },
    customer() {
      this.$http.get("/jeecg-boot/xrcx/myCustomer/list").then((res) => {
        this.customerList = res.result.records;
      });
    },
    // 新闻资讯
    getNews() {
      let data = {};
      this.$API.getNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);

        this.newsList = res.data;
        // this.newsList.map((item, index) => {
        //   console.log(item);
        // });

        this.newsList1 = this.newsList.slice(1, 3);
        // console.log(this.newsList[0].cnContent.split("<br/>")[0]);
        // console.log(
        //   this.newsList[0].cnContent.split("<br/>")[0].split("<p>")[1]
        // );
      });
    },
    // 关于我们
    getArticle() {
      let data = {
        infoTypeId: 8,
      };
      this.$API.getArticleList(data).then((res) => {
        console.log("res :文章>> ", res);
        this.AboutUsInfor = res.data;
      });
    },
    //了解水利
    gotoWater_conservancy() {
      // console.log(val);
      this.$router.push({
        path: "/product",
        // query: { id: val }
        query: { id: 0 },
      });
    },
    //了解城管
    gotoCity_inspectors(val) {
      console.log(val);
      this.$router.push({
        path: "/product",
        query: { id: val },
      });
    },
    //了解农业
    gotoAgriculture(val) {
      this.$router.push({
        path: "/product",
        query: { id: val },
      });
    },
    //了解软件
    gotoSoftware(index) {
      if (index == 0) {
        this.$router.push({
          path: "/product",

          query: {
            //   name: "#software_customization",
            id: 11,
          },
        });
      } else {
        this.$router.push({
          path: "/product",
          query: {
            // name: "#hardware_customization",
            id: 12,
          },
        });
      }
    },
    // 更多资讯
    gotoNews() {
      this.$router.push({
        path: "/news",
      });
    },
    // 新闻详情左
    gotoNewsDetailLeft() {
      let id = this.newsList[0].cnId;
      let data = { cnId: id };
      this.$router.push({ path: `/NewsDetail/${id}` });
    },
    // 新闻详情右
    gotoNewsDetailRight(val) {
      console.log(val);
      let id = val.cnId;
      let data = { id };
      this.$router.push({ path: `/NewsDetail/${id}` });
    },
    //了解我们
    gotoAbout() {
      this.$router.push({ path: "/about" });
    },
    gotoContact() {
      // this.$router.push({ path: "/contact", query: { name: "#content1" } });
      this.$router.push({
        path: "/contact",
        query: {
          name: "#Message",
        },
      });
    },
    gotoMerchants() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#opportunity",
        },
      });
    },
    gotoHardwareDetail() {
      this.$router.push({
        path: "/product",
        query: { id: 9 },
      });
    },
    gotoprogramme1() {
      this.$router.push({
        path: "/programme",
        // query: { name: "#" },
      });
    },
  },
};
</script>
<style scoped lang="less">
h2 {
  font-size: 32px;
}
img:nth-child(1) {
  width: 100%;
}
a {
  color: #fff;
}
.content {
  width: 100%;
  .el-carousel {
    width: 100%;
    height: 700px;
  }
  /deep/ .el-carousel__container {
    height: 100% !important;
  }
  // 产品和服务
  .Products {
    width: 60%;
    margin: 50px auto;
    .title {
      display: flex;
      span {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      span:nth-child(3) {
        font-size: 32px;
        font-weight: 600;
        // margin-bottom: 20px;
      }
      span:nth-child(1) {
        display: block;
        width: 8px;
        height: 32px;
        background-color: #004aa1;
        margin-right: 10px;
        margin-top: 7px;
      }
    }
    // .title {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   flex-direction: column;
    //   span:nth-child(3) {
    //     display: block;
    //     width: 70px;
    //     height: 6px;
    //     background-color: #004aa1;
    //   }
    //   span {
    //     font-size: 32px;
    //     font-weight: 600;
    //     margin-bottom: 20px;
    //   }
    // }
    .tabs {
      width: 100%;
      margin: 20px auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        width: 100%;
      }
    }
    .tabs_Hardware {
      position: relative;
      .img1,
      .img2 {
        position: absolute;
        left: 0;
        top: 150px;
        width: 40px;
      }
      .img1 {
        left: -80px;
        top: 180px;
      }
      .img2 {
        left: 1180px;
        top: 180px;
      }
      .list {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .item {
          width: 23%;

          margin: 2% 1%;
          height: 400px;
          background-color: #004aa1;
          border-radius: 10px;
          box-shadow: 0 0 10px 2px rgb(210, 210, 210);
          .devImg {
            width: 100%;
            height: 300px;
            // border-radius: 8px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fff;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
          .text {
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            color: #fff;
            p {
              width: 80%;
              display: flex;
              justify-content: center;
              font-size: 18px;
              font-weight: 600;
            }
            button {
              cursor: pointer;
              display: block;
              padding: 5px 40px;
              border: 1px solid #fff;
              border-radius: 20px;
              color: white;
              background-color: #004aa1;
            }
            button:hover {
              color: black;
              background-color: #fff;
            }
            span:hover {
              background-color: #fff;
              color: #004aa1;
            }
          }
        }
        .gotoHardwareDetail {
          cursor: pointer;
          display: block;
          padding: 5px 40px;
          border: 1px solid #fff;
          border-radius: 20px;
          a {
            color: white;
          }
          background-color: #004aa1;
          line-height: 30px;
          margin-top: 20px;
        }
        .gotoHardwareDetail:hover {
          a {
            color: black;
          }
          // background-color: #fff;
        }
      }
    }
    .tabs_software {
      .lists {
        .items {
          margin-top: 20px;
          border-bottom: 1px dashed #999;
          .project_name {
            display: flex;
            align-items: center;
            span {
              font-size: 20px;
              font-weight: 600;
              color: #1196db;
              // #1196db
              // #eaa057
              // #01b49e
            }
            img {
              width: 30px;
              height: 24px;
              margin-right: 10px;
            }
          }
          .list {
            margin: 20px 0;
            display: flex;
            .item {
              width: 20%;
              display: flex;
              flex-direction: column;
              align-items: center;
              img {
                width: 80px;
                height: 80px;
                cursor: pointer;
              }
              span {
                margin-top: 10px;
              }
            }
            .more {
              width: 15%;
              display: flex;
              align-items: center;
              justify-content: center;
              span,
              a {
                cursor: pointer;
                display: block;
                width: 80%;
                padding: 5px;
                border-radius: 20px;
                border: 1px solid #1196db;
                color: #1196db;
                font-weight: 600;
              }
              span:hover {
                background-color: #1196db;
                color: #fff;
              }
              a:hover {
                background-color: #1196db;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .tabs_other {
      .item {
        display: flex;
        .left_img {
          width: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right_text {
          width: 46%;
          padding: 40px 20px;
          img {
            width: 30px;
            height: 30px;
            float: left;
          }
          .tilte {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:nth-child(1) {
              font-size: 20px;
              color: #0495dd;
              font-weight: 600;
            }
            span:nth-child(2) {
              font-size: 16px;
              color: #004aa1;
              font-weight: 600;
              cursor: pointer;
              text-align: right;
            }
            a {
              font-size: 16px;
              color: #004aa1;
              font-weight: 600;
              cursor: pointer;
              text-align: right;
            }
          }
          p {
            display: block;
            padding: 20px 0;
            border-bottom: 1px dashed #999;
            text-align: left;
          }
          .service_list {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
            .sitem {
              width: 25%;
              padding: 2% 4%;
              display: flex;
              justify-content: flex-start;
              img {
                width: 22px;
                height: 22px;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .item:nth-child(2n) {
        flex-direction: row-reverse;
      }
    }
  }
  // 行业解决方案
  .Solutions {
    width: 100%;
    height: 90vh;
    padding: 5vh 20%;
    background-color: #174789;
    .title {
      display: flex;
      align-items: center;
      span:nth-child(1) {
        display: block;
        width: 8px;
        height: 32px;
        background-color: #fff;
        margin-right: 10px;
        margin-top: 7px;
      }
      span:nth-child(2) {
        color: #fff;
        font-size: 36px;
        font-weight: 600;
      }
    }
    .describe {
      color: #fff;
      font-size: 20px;
      text-align: left;
      margin: 30px 0;
      position: relative;
      .describe_p {
        position: absolute;
        top: -50px;
        left: 54.5%;
        font-weight: 700;
        cursor: pointer;
        a {
          color: #fff;
        }
      }
    }
    .content {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      .item_list:nth-child(1) {
        background-image: url("../assets/home/u185.png");
        background-repeat: no-repeat;
      }
      .item_list:nth-child(2) {
        background-image: url("../assets/home/u188.png");
        background-repeat: no-repeat;
      }
      .item_list:nth-child(3) {
        background-image: url("../assets/home/u191.png");
        background-repeat: no-repeat;
      }
      .item_list {
        width: 33%;
        height: 600px;
        cursor: pointer;
        .lists {
          display: none;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 0 50px;
          .title {
            padding-top: 90px;
            margin-left: -170px;
            display: flex;
            flex-direction: column;
            // margin-bottom: -10px;
            img {
              width: 56px;
              margin-left: -54px;
            }
            span {
              font-size: 28px;
              margin-top: 10px;
            }
          }

          .list {
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            margin-top: 30px;
            .item {
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              margin-bottom: 8px;
              // width: 3%;
            }
            .itemM {
              margin-top: 20px;
              // width: 3%;
            }
            .item1 {
              color: #fff;
              line-height: 25px;
              margin-top: 60px;
            }

            a:hover {
              color: #68a8f0;
            }
          }
        }
      }
      .item_list:hover {
        .lists {
          display: block;
        }
      }
    }
  }
  // 新闻资讯
  .News_infor {
    width: 60%;
    margin: 50px 20%;
    height: 70vh;
    .top {
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          display: block;
          width: 8px;
          height: 32px;
          background-color: #004aa1;
          margin-right: 10px;
        }
        span:nth-child(2) {
          font-size: 36px;
          font-weight: 600;
        }
      }
      .more_btn {
        margin-top: 10px;
        a {
          cursor: pointer;
          color: #004aa1;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    .content_infor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_infor {
        width: 55%;
        border-radius: 5px;
        background-color: #cee8fc;
        box-shadow: 2px 5px 5px 1px #cee8fc;
        .el-carousel {
          height: 350px !important;
          border-radius: 5px;
        }
        .text {
          padding: 30px;
          height: 155px;
          p:nth-child(1) {
            font-size: 16px;
            color: #999;
          }
          p:nth-child(2) {
            font-size: 20px;
            font-weight: 600;
            color: #004aa1;
          }
          p:nth-child(3) {
            font-size: 18px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          span {
            cursor: pointer;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: #004aa1;
            border-bottom: 1px solid #004aa1;
          }
        }
      }
      .right_infor {
        width: 42%;
        height: 580px;
        display: flex;
        margin-top: 14px;
        flex-direction: column;
        justify-content: flex-start;
        border-top: 2px solid #004aa1;
        .infor_item {
          margin-bottom: 20px;
          padding: 23px 20px;
          height: 230px;
          box-shadow: 0px 5px 5px 1px #cee8fc;
          border-radius: 5px;
          margin-top: 5px;
          p:nth-child(1) {
            font-size: 16px;
            color: #999;
            margin-bottom: 20px;
            margin-top: 15px;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-weight: 600;
            color: #004aa1;
          }
          p:nth-child(3) {
            font-size: 16px;
            padding-top: 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          span {
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            color: #004aa1;
            border-bottom: 1px solid #004aa1;
            padding-top: 20px;
          }
        }
      }
    }
  }
  // 关于我们
  .About_us {
    position: relative;
    .bg {
      width: 1060px;
      height: 680px;
      background-image: url("../assets/about/关于我们.jpg");
    }
    .text {
      width: 40%;
      height: 500px;
      margin-left: 40%;
      position: absolute;
      top: 50px;
      background-color: #fff;
      padding: 50px;

      border-radius: 10px;
      // box-shadow: 0 0 1px 1px;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
      //  box-shadow: 0 0 10px 5px rgba(0 0 0 / 8%);
      // box-shadow: 0 0 10px rgb(135 135 135 / 20%);
      // box-shadow: 0 0 10px rgb(0 0 0 / 8%);
      .title {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          display: block;
          width: 8px;
          height: 32px;
          background-color: #004aa1;
          margin-right: 10px;
        }
        span:nth-child(2) {
          font-size: 36px;
          font-weight: 600;
        }
      }
      p {
        margin: 20px 0;
        line-height: 30px;
        font-weight: 600;
        font-family: "MicrosoftYaHei";
      }
      .About_us_btn {
        cursor: pointer;
        width: 120px;
        text-align: center;
        border-radius: 25px;
        background-color: #004aa1;
        color: #fff;
        padding: 8px;
        position: absolute;
        bottom: -30px;
        margin-bottom: 80px;
        margin-left: 3%;
        margin-top: 20px;
      }
    }
    .title_div {
      padding: 20px;
    }
  }
  // 介绍
  .introduce {
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .item {
      width: 280px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 30px 0;
      img {
        width: 50px;
      }
      p {
        font-size: 18px;
      }
      p:nth-child(2) {
        font-weight: 600;
      }
    }
    .item:hover {
      box-shadow: 0px 0px 5px 0px #004aa1;
    }
  }
  // 咨询联系
  .Inquiry {
    text-align: center;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #004aa1;
    color: #fff;
    p:nth-child(1) {
      font-size: 44px;
    }
    p:nth-child(2) {
      font-size: 18px;
    }
    .btn {
      width: 30%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      button {
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        width: 150px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 25px;
        color: #fff;
        background-color: #004aa1;
      }
      button:hover {
        background-color: #fff;
        a {
          color: #004aa1;
        }
      }
    }
  }
}
</style>
